// EditUser.js

import React from 'react';
import { DateInput, Edit, SimpleForm, TextInput,BooleanInput,NumberInput } from 'react-admin';

const EditUser = (props) => (

  <Edit {...props}>
    <SimpleForm>
      <TextInput source="userCode" disabled />
      <TextInput source="createdAt" disabled />
      <TextInput source="hasSignedUp" disabled />
      <TextInput source="hasEnded" />
      <TextInput source="email" />
      <TextInput source="password" />
      <DateInput  source='appEndingDate'/>
      <BooleanInput source="groupSettings.enabled" label="Group Enabled" />
      <NumberInput source="groupSettings.numberOfGroups" label="Number of Groups"  max={4} min={1}/>
     {props.vari? <TextInput source="signUpCode" disabled />:<TextInput source="signUpCode"/>} 
      
    </SimpleForm>
  </Edit>
);

export default EditUser;
