import React from 'react';
import { Create, SimpleForm, TextInput, useNotify, useRedirect,DateInput, BooleanInput, NumberInput} from 'react-admin';
import { useCreate } from 'react-admin';

const CreateUsers = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const [create, { loading }] = useCreate('users');

  const handleSubmit = async (values) => {
 
    try {
      const { enabled, numberOfGroups, ...otherValues } = values;
      const dataToSubmit = {
        ...otherValues,
        groupSettings: {
          enabled,
          numberOfGroups,
        },
      };

      await create(
        'users', // Pass the resource name directly
        { data: dataToSubmit },
        {
          onSuccess: ({ data }) => {
            notify('User created successfully');
            redirect('list', props.basePath);
          },
          onFailure: (error) => {
            notify(`Error: ${error.message}`, 'warning');
          },
        }
      );
    } catch (error) {
      console.error('Error during create:', error);
    }
  };

  return (
    <Create {...props}>
      <SimpleForm onSubmit={handleSubmit}>
        <TextInput source="cardType" />
        <TextInput source="signUpCode" />
        <DateInput source='appEndingDate'  />
        <BooleanInput source="enabled" label="Group Enabled" />
        <NumberInput source="numberOfGroups" label="Number of Groups"  max={4} min={1}/>
      </SimpleForm>
    </Create>
  );
};

export default CreateUsers;
