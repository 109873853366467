

export const authProvider = {
    // called when the user attempts to log in
    login: async ({ username,password }) => {
        const apiUrl = process.env.REACT_APP_BASEURL;
      
        const url = `${apiUrl}/login`;
        const method = 'POST';
        const headers = {
          'Content-Type': 'application/json',
        };
        const body = JSON.stringify({ username, password });

        try {
            const response = await fetch(url, { method, headers, body , credentials: 'include'});
            const data = await response.json();
             if(response.ok){
                return Promise.resolve();
             }else {
                // Authentication failed
                return Promise.reject(new Error(data.message || 'Authentication failed'));
              }
        } catch (error) {
             console.log(error)
        }
      
    },
    // called when the user clicks on the logout button
    logout:async() => {

        const apiUrl =  process.env.REACT_APP_BASEURL;
        const url = `${apiUrl}/logout`;
        const method = 'GET';
        try {
            const response = await fetch(url, { method, credentials: 'include'});
            const data = await response.json()
        } catch (error) {
            
        }
    },
    // called when the API returns an error
    checkError: ({ status }) => {
        if (status === 401 || status === 403) {
            return Promise.reject();
        }
        return Promise.resolve();
    },
    // called when the user navigates to a new location, to check for authentication
    checkAuth: async() => {
        const apiUrl =  process.env.REACT_APP_BASEURL;
      
        const url = `${apiUrl}/me`;
        const method = 'GET';

        try {
            const response = await fetch(url, { method, credentials: 'include'});
            const data = await response.json();
            if(response.ok){
                return Promise.resolve();
            }else{
              return Promise.reject(new Error(data.message || 'session failed'));
            }
        } catch (error) {
            console.log(error)
        }
    
    },
  
};