
import React from 'react';
import { Filter, TextInput } from 'react-admin';

const UserSearchFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search by User Code" source="userCode" alwaysOn />
  </Filter>
);

export default UserSearchFilter;
